import React, { useState, useMemo } from "react";
import GreenMultiSelect from "../Form/GreenMultiSelect";
import PrepareAdData from "./PrepareAdData";
import { FormControlLabel, Button, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import GreenInput from "../Form/GreenInput";
import dayjs from "dayjs";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#193d66",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#ffffff" : "#ffffff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#e8e8e8" : "#e8e8e8",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// Define the validation schema
const schema = yup.object().shape({
  service_ids: yup.array().min(1, "Select at least one service").required(),
  massage_ids: yup.array(),
});

export default function MassageAndServicesForm({
  handleTabsChange,
  adsData,
  adData,
  setAdData,
}) {
  const { t } = useTranslation();
  const [rates, setRates] = useState([]);

  const {
    serviceOptionValues,
    massageOptionValues,
    rateOptionValues,
    serviceHourOptionValues,
  } = PrepareAdData(adsData);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      service_ids: adData.service_ids || [],
      massage_ids: adData.massage_ids || [],
    },
  });

  const massageSelectionFlag = useMemo(() => {
    const massageTypeId = serviceOptionValues.find(
      (service) => service.label == "Massage"
    )?.value;
    return adData.service_ids.some((service) => service.value == massageTypeId);
  }, [adData, serviceOptionValues]);

  const checkFormDataStatus = useMemo(() => {
    return (
      adData.service_ids.length === 0 ||
      adData.service_hour_ids.length === 0 ||
      adData.rate_ids.length === 0
    );
  }, [adData]);

  const handleChange = (data, name) => {
    if (name === "service_ids") {
      const ids = data.map((service) => service.value);
      const selectedRates = adsData.rates.filter((ad) =>
        ids.includes(ad.service_id)
      );
      setAdData((prevState) => ({
        ...prevState,
        service_ids: data,
        massage_ids: massageSelectionFlag ? adData.massage_ids : [],
      }));
      setRates(selectedRates);
      setValue("service_ids", data, { shouldValidate: true });
    } else if (name === "massage_ids") {
      setAdData((prevState) => ({
        ...prevState,
        massage_ids: data,
      }));
      setValue("massage_ids", data, { shouldValidate: true });
    } else if (["starting_price", "time_duration"].includes(name)) {
      setAdData((prevState) => ({
        ...prevState,
        [name]: data,
      }));
      setValue(name, data, { shouldValidate: true });
    } else {
      setAdData((prevState) => ({
        ...prevState,
        [name]: [...adData[`${name}`], data],
      }));
      setValue(name, [...adData[`${name}`], data], { shouldValidate: true });
    }
  };

  const onSubmit = (data) => {
    handleTabsChange(data, 2);
    setAdData((prevData) => ({
      ...prevData,
      ...data,
    }));
  };

  return (
    <div className="newAdModal__body__textFields">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="newAdModal__body__textFields__group newAdModal__body__textFields__group2">
          <div className="newAdModal__body__textFields__group2__textField newAdModal__body__textFields__group__textField">
            <div>
              <GreenMultiSelect
                label={t("providers.myAd.SelectServices")}
                options={serviceOptionValues}
                name="service_ids"
                value={adData.service_ids}
                handleChange={handleChange}
                {...register("service_ids")}
              />
              {errors.service_ids && (
                <p className="error">{errors.service_ids.message}</p>
              )}
            </div>
          </div>
          {massageSelectionFlag && (
            <div className="newAdModal__body__textFields__group2__textField newAdModal__body__textFields__group__textField">
              <div>
                <GreenMultiSelect
                  label={t("providers.myAd.SelectMassageTypes")}
                  options={massageOptionValues}
                  name="massage_ids"
                  value={adData.massage_ids}
                  handleChange={handleChange}
                  {...register("massage_ids")}
                />
                {errors.massage_ids && (
                  <p className="error">{errors.massage_ids.message}</p>
                )}
              </div>
            </div>
          )}
        </div>
        {/* <h3>{t("providers.myAd.serviceHour")}</h3> */}
        {/* <div className="newAdd__tablecontainer">
          <div style={{ width: "50%" }}>
            <GreenInput
              label={t("providers.myAd.timeDuration")}
              type="number"
              name="time_duration"
              onChange={(e) => handleChange(e.target.value, e.target.name)}
              register={register("time_duration")}
              error={errors.time_duration?.message}
            />
            {errors.time_duration && (
              <p className="error">{errors.time_duration.message}</p>
            )}
          </div>
          <div style={{ width: "50%" }}>
            <GreenInput
              label={t("providers.myAd.startingPrice")}
              type="number"
              name="starting_price"
              onChange={(e) => handleChange(e.target.value, e.target.name)}
              register={register("starting_price")}
              error={errors.starting_price?.message}
            />
            {errors.starting_price && (
              <p className="error">{errors.starting_price.message}</p>
            )}
          </div>
        </div> */}
        {adsData.service_hours.length > 0 && (
          <h3>{t("providers.myAd.YourServiceHours")}</h3>
        )}
        <section className="addForm__serviceHourTable">
          {adsData.service_hours.map((data) => (
            <div className="addForm__serviceHourTable__container" key={data.id}>
              <h3 className="addForm__serviceHourTable__container__day">
                {data.service_type == "holiday"
                  ? `${dayjs(data.holiday).format("d MMM, YYYY")}`
                  : data.day}
              </h3>
              <h4 className="serviceHour__table__title">
                {" "}
                {t("client.title.From")}
              </h4>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label>{t("client.title.Time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.from_time} readOnly />
                  </div>
                </div>
              </div>
              <h4 className="serviceHour__table__title">
                {" "}
                {t("client.title.To")}
              </h4>
              <div className="serviceHour__table__textField">
                <div className="serviceHour__table__textField__greenInput">
                  <label>{t("client.title.Time")}</label>
                  <div className="serviceHour__table__textField__greenInput__inputBox">
                    <input type="time" value={data.to_time} readOnly />
                  </div>
                </div>
              </div>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    onChange={() => handleChange(data.id, "service_hour_ids")}
                    checked={adData.service_hour_ids.includes(data.id)}
                  />
                }
              />
            </div>
          ))}
        </section>
        {adsData.rates.length > 0 && <h3>{t("providers.myAd.serviceRate")}</h3>}
        <section className="addForm__serviceHourTable">
          {adsData.rates.map((rate) => (
            <div
              className="rate__tablecontainer__table__section__container"
              key={rate.id}
            >
              <div className="rate__tablecontainer__table__section__container__dollartextfield">
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                  <label>{t("providers.myRate.price")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={`$ ${rate.price}`} readOnly />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__dollartextfield">
                <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput">
                  <label>{t("providers.myRate.timeDuration")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <icon className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox__icon"></icon>
                    <input value={rate.time_duration} readOnly />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.description")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.description} readOnly />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.rateType")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.rate_type} readOnly />
                  </div>
                </div>
              </div>
              <div className="rate__tablecontainer__table__section__container__Regularbox">
                <div className="greenSelect">
                  <label>{t("providers.myRate.service")}</label>
                  <div className="rate__tablecontainer__table__section__container__dollartextfield__greenInput__inputBox">
                    <input value={rate.service.name} readOnly />
                  </div>
                </div>
              </div>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    onChange={() => handleChange(rate.id, "rate_ids")}
                    checked={adData.rate_ids.includes(rate.id)}
                  />
                }
              />
            </div>
          ))}
        </section>
        <div className="adVideoForm">
          <div className="adVideoForm__buttons">
            <Button
              variant="contained"
              className="newAdModalButton"
              onClick={(e) => handleTabsChange(e, 0)}
            >
              {t("providers.myAd.back")}
            </Button>
            <Button
              variant="contained"
              className="newAdModalButton"
              type="submit"
            >
              {t("providers.myAd.next")}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
