import React, { useEffect, useState } from "react";
import TryUsCard from "./Components/TryUsCard";
import BasicCard from "./Components/BasicCard";
import GeneralApis from "../../apis/GeneralApis";
import { useTranslation } from "react-i18next";
import Loading from "../general/Loading";

export default function Packages({ trial = true, subscription=null, client=false }) {
  const { t } = useTranslation();
  const { getPackages, isApiLoading } = GeneralApis();
  const [packages, setPackages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  const fetchPackages = async () => {
    debugger
    const response = await getPackages();
    if (response.data) {
      setPackages(response.data);
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  if (errorMessage) return null;

  return (
    <React.Fragment>
      {isApiLoading ? (
        <div className="circularLoading">
          <Loading />
        </div>
      ) : (
        <section className="selectLevel__body">
          <h2 className="selectLevel__body__heading">
            {t("providers.myLevel.choosePlan")}
          </h2>
          <h3 className="selectLevel__body__subHeading">
            {t("providers.myLevel.upgradeAccount")}
          </h3>

          <aside className="selectLevel__body__cards">
            {packages.length > 0 && trial && (
              <TryUsCard
                client={client}
                levelPackage={packages.find(
                  (pack) => pack.package_type == "Diamond"
                )}
              />
            )}
            {packages.map((pack) => (
              <BasicCard client={client} levelPackage={pack} subscription={subscription} />
            ))}
          </aside>
        </section>
      )}
    </React.Fragment>
  );
}
