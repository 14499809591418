import React from "react";
import AuthenticationTabs from "../components/AuthenticationTabs";
import { useNavigate } from "react-router-dom";
const SignIn = () => {
  const navigate = useNavigate();
  return (
    <div className="authentication">
      <div className="authentication__container">
        <div className="authentication__container__left">
          <div className="authentication__container__left__logo">
            <img
              src={require("../../../assets/logo.jpeg")}
              alt=""
              className="authentication__container__left__logo__logoImage"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="authentication__container__left__box">
            <img
              src={require("../../../assets/SignIn.png")}
              alt=""
              className="authentication__container__left__box__image"
            />
          </div>
          <div className="authentication__container__left__stripe"></div>
        </div>
        <div className="authentication__container__right">
          <AuthenticationTabs />
        </div>
      </div>
      <div className="authentication__stripe"></div>
    </div>
  );
};

export default SignIn;
