import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="footer__top">
        <div className="footer__top__phone">
          <p onClick={() => navigate("/subscription-details")}>Subscription Details</p>
        </div>
        <div className="footer__top__phone">
          <p onClick={() => navigate("/contact-us")}>Contact Us</p>
        </div>
        <div className="footer__top__phone">
          <p onClick={() => navigate("/privacy-policy")}>
            {t("client.footer.policy")}
          </p>
        </div>
        <div className="footer__top__phone">
          <p onClick={() => navigate("/term-&-conditions")}>
            {t("client.footer.terms")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
