import React from "react";
import Packages from "../../../../components/Packages/Packages";
import ClientPage from "../../../../components/PageWrapper/ClientPage"; 
export default function SelectLevel() {
  return (
    <ClientPage>
      <Packages trial={true} client={true}/>
    </ClientPage>
  );
}
