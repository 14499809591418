import * as React from "react";
import { Box, Modal } from "@mui/material";
import BasicModalOptions from "./BasicModalOptions";
import BlueButton from "../../Form/BlueButton";
import { useTranslation } from "react-i18next";
import ProfileSetup from "../../../apis/provider/ProfileSetup";
import { useNavigate } from "react-router-dom";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  outline: "none",
  p: 2,
  borderRadius: "1rem",
};

export default function TryUsModal({ open, handleClose, levelPackage, client }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createTrialAccount, isLoading } = ProfileSetup();

  const handleAccount = async () => {
    const response = await createTrialAccount();
  };

  return (
    <main>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <section className="basicCardModal">
            <header className="basicCardModal__header">
              <h3> {t("providers.myLevel.trylevel")}</h3>
            </header>
            <h4> You can access all feature</h4>

            <aside className="basicCardModal__body">
              <BasicModalOptions levelPackage={levelPackage} />
            </aside>
          </section>
          <div className="basicCardModal__body__saveButton">
            <div>
            
              <BlueButton
                title={t("providers.myLevel.continue")}
                loading={isLoading}
                onClick={client ? () => navigate("/signin") : handleAccount}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </main>
  );
}
